import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Container from '../../shared/Container/Container'
import Button from '../../shared/Button/Button'
import Edge from '../../shared/Edge/Edge'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Heading from '../../shared/Heading/Heading'

// Local
import Faqs from './Faqs'

const PageFaqs = (props) => {
  return (
    <section
      className={classNames('pb-16 md:pb-32', {
        [`bg-${props.data.primary.background_color}`]: props.data.primary.background_color
      })}
    >
      {props.data.primary.faqs_has_top_edge && (
        <Edge
          location='top'
          direction='right'
          outerClassName='text-white'
          innerClassName={`text-${props.data.primary.background_color}`}
        />
      )}
      <Container
        width='thinner'
        className={classNames({
          'pt-20': props.data.primary.faqs_has_top_edge
        })}
      >
        <Heading
          className='h2 mb-12'
          {...props.data.primary.faqs_title}
        />
        <Faqs
          className='mb-12'
          canAnimate
          items={props.data.items.map((item) => {
            return {
              header: item.faqs_header.text,
              body: (
                <HtmlContent
                  html={item.faqs_body.html}
                  className='c-prose'
                />
              )
            }
          })}
        />
        <div>
          <Button
            {...props.data.primary.faqs_cta_link}
            color={props.data.primary.cta_style}
            icon
          >
            {props.data.primary.faqs_cta_label.text}
          </Button>
        </div>
      </Container>
    </section>
  )
}

PageFaqs.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageFaqs

export const query = graphql`
  fragment PageFaqs on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyFaqs {
          id
          slice_type
          primary {
            display
            faqs_title {
              text
              raw
            }
            faqs_cta_label {
              text
            }
            faqs_cta_link {
              ...Link
            }
            cta_style
            background_color
            faqs_has_top_edge
          }
          items {
            faqs_header {
              text
            }
            faqs_body {
              html
            }
          }
        }
      }
    }
  }
`
