import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import Heading from '../../shared/Heading/Heading'
import FollowLinks from '../../shared/FollowLinks/FollowLinks'

// Icons
import LogoSymbolRed from '../../../assets/img/logo-symbol-red.svg'

const PreFooter = (props) => {
  const { data, location } = props
  return (
    <section
      className={classNames('relative overflow-hidden bg-primary c-pre-footer', {
        [`text-${data.primary.background_color}`]: data.primary.background_color
      })}
    >
      <Container className='relative z-10'>
        <Heading
          className='h3 mb-6 text-white text-center'
          {...data.primary.pre_footer_title}
        />
        {!!data.items.length && (
          <div className='flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4'>
            {data.items.map((cta, ctaIndex) => {
              return (
                <Button
                  key={ctaIndex}
                  {...cta.pre_footer_cta_link}
                  color={cta.pre_footer_cta_style}
                  icon
                  className={classNames({
                    'bg-white border-white hover:border-secondary-hover hover:bg-secondary-hover hover:text-midnight': cta.pre_footer_cta_style === 'secondary',
                    'border-primary-active bg-transparent': cta.pre_footer_cta_style === 'primary'
                  })}
                >
                  {cta.pre_footer_cta_label.text}
                </Button>
              )
            })}
          </div>
        )}
        {data.primary.pre_footer_show_share_links && (
          <FollowLinks
            className='mt-10'
            title='share links title'
            url={location.pathname}
          />)}
      </Container>
      <div className='c-pre-footer__logo'>
        <LogoSymbolRed />
      </div>
    </section>
  )
}

PreFooter.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default PreFooter

export const query = graphql`
  fragment PreFooter on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyPreFooter {
          id
          slice_type
          primary {
            pre_footer_title {
              text
              raw
            }
            background_color
            pre_footer_show_share_links
          }
          items {
            pre_footer_cta_style
            pre_footer_cta_label {
              text
            }
            pre_footer_cta_link {
              ...Link
            }
          }
        }
      }
    }
  }
`
