import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import Edge from '../../shared/Edge/Edge'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Embed from '../../shared/Embed/Embed'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'
import LogoGroup from '../../shared/LogoGroup/LogoGroup'

const InfoBlock = (props) => {
  return (
    <section
      className={classNames('bg-offwhite lg:overflow-visible ', {
        'mb-16 md:mb-32': !props.data.primary.remove_bottom_margin
      })}
    >
      <Edge
        location='top'
        direction='right'
        outerClassName='text-white'
        innerClassName='text-offwhite'
      />
      <div className='pb-10 md:pb-20 lg:pt-10'>
        <Container width='thin' className='relative z-10'>
          <div className='md:flex items-end -mt-16 mb-10 sm:pt-8 md:pt-0 md:mt-0 lg:-mt-40 xl:-mt-56'>
            <div className='md:w-1/2 md:order-2'>
              <div className='max-w-2xl lg:max-w-none lg:w-full xl:w-120p'>
                <Image
                  fallbackAlt={props.data.primary.info_block_title.text}
                  {...props.data.primary.info_block_illustration}
                />
              </div>
            </div>
            <Heading
              className='h2 md:w-1/2 md:order-1'
              {...props.data.primary.info_block_title}
            />
          </div>
          <div className='md:flex space-y-8 md:space-y-0 md:space-x-4 mb-10 sm:mb-20'>
            {props.data.items.map((item, itemIndex) => {
              return (
                <div
                  key={itemIndex}
                  className='md:w-1/2'
                >
                  <div className='md:max-w-md'>
                    <div className='space-y-2 mb-4 md:mb-6'>
                      <Heading
                        className='h5'
                        {...item.info_block_header}
                      />
                      <HtmlContent
                        html={item.info_block_body.html}
                        className='c-prose'
                      />
                    </div>
                    {(item.info_block_cta_label.text && item.info_block_cta_link.url) && (
                      <Button
                        color='inline'
                        icon
                        {...item.info_block_cta_link}
                      >
                        {item.info_block_cta_label.text}
                      </Button>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <LogoGroup
            logosDocument={props.data.primary.logo_group.document}
            fallbackAlt={props.data.primary.info_block_title.text}
          />
        </Container>
      </div>
      {props.data.primary.info_block_video_embed.url && (
        <div className='relative'>
          <Container width='thinner' className='relative z-10'>
            <Embed
              embed={props.data.primary.info_block_video_embed}
              title={props.data.primary.info_block_video_embed.title}
            />
          </Container>
          <div className='absolute h-1/2 bottom-0 left-0 w-full bg-white' />
        </div>
      )}
    </section>
  )
}

InfoBlock.propTypes = {
  data: PropTypes.object.isRequired
}

export default InfoBlock

export const query = graphql`
  fragment InfoBlock on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyInfoBlock {
          id
          slice_type
          primary {
            display
            info_block_title {
              text
              raw
            }
            info_block_video_embed {
              ...Embed
            }
            info_block_illustration {
              ...Image
            }
            logo_group {
              ...LogoGroup
            }
            remove_bottom_margin
          }
          items {
            info_block_cta_label {
              text
            }
            info_block_cta_link {
              ...Link
            }
            info_block_body {
              html
            }
            info_block_header {
              text
              raw
            }
          }
        }
      }
    }
  }
`
