import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, useStaticQuery } from 'gatsby'
import classNames from 'classnames'

// Generic
import Container from '../../shared/Container/Container'
import Heading from '../../shared/Heading/Heading'
import Pill from '../../shared/Pill/Pill'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'

// Icons
import ChevronRight from '../../../assets/img/chevron-right.svg'

const CareersList = (props) => {
  const careers = useStaticQuery(graphql`
    {
      entries: allPrismicCareer(
        sort: {
          fields: data___published,
          order: DESC
        }
      ) {
        nodes {
          id
          url
          data {
            title {
              text
            }
            locations {
              location {
                text
              }
            }
          }
        }
        totalCount
      }
    }
  `)

  return (
    <section className='mb-16 md:mb-24' id='careers'>
      <Container width='thinnest'>
        <Heading
          className='h2 mb-12'
          {...props.data.primary.careers_list_title}
        />
        <div className={classNames({ 'divide-y divide-gray-lighter border-t border-b border-gray-lighter': careers.entries.nodes.length > 0 })}>
          {(careers.entries.nodes.length > 0) ? (
            careers.entries.nodes.map((career) => {
              return (
                <Link
                  key={career.id}
                  to={career.url}
                  className='flex items-center py-6 hover:bg-offwhite'
                >
                  <div className='sm:flex items-center space-y-2 sm:space-y-0'>
                    <p className='mr-4'>
                      {career.data.title.text}
                    </p>
                    <div className='space-x-2'>
                      {career.data.locations.map((item, itemIndex) => {
                        return (
                          <Pill
                            key={itemIndex}
                            color='primary'
                          >
                            {item.location.text}
                          </Pill>
                        )
                      })}
                    </div>
                  </div>
                  <ChevronRight className='text-primary h-3 fill-current ml-auto mr-2' />
                </Link>
              )
            })
          ) : (
            <HtmlContent
              className='text-lg c-prose'
              html={props.data.primary.careers_list_no_careers_copy.html}
            />
          )}
        </div>
      </Container>
    </section>
  )
}

CareersList.propTypes = {
  data: PropTypes.object.isRequired
}

export default CareersList

export const query = graphql`
  fragment CareersList on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyCareersList {
          id
          slice_type
          primary {
            display
            careers_list_title {
              text
              raw
            }
            careers_list_no_careers_copy {
              html
            }
          }
        }
      }
    }
  }
`
