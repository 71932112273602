import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const TwoUpItem = (props) => {
  const { children, pullUp } = props

  return (
    // `w-full` here so that both blocks are the same width
    <div className='max-w-lg mx-auto md:mx-0 w-full'>
      <div className={classNames('px-6 md:px-10 py-12 md:py-14 shadow-lg bg-white', { 'md:-mt-20': pullUp })}>
        {children}
      </div>
    </div>
  )
}

TwoUpItem.propTypes = {
  classname: PropTypes.string,
  pullUp: PropTypes.bool.isRequired
}

export default TwoUpItem
