import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Generic
import Container from '../../shared/Container/Container'
import Edge from '../../shared/Edge/Edge'
import Image from '../../shared/Image/Image'
import Modal from '../../shared/Modal/Modal'
import Heading from '../../shared/Heading/Heading'

// Local
import TeamGridModalContent from './TeamGridModalContent'

const TeamGrid = (props) => {
  const modalElement = useRef(null)

  const [openTeamMember, setOpenTeamMember] = useState(null)

  /**
   * Simple function to open the team member modal
   * @param  {Object} event
   * @param  {Number} teamMemberIndex
   * @return {Void}
   */
  const handleOpenTeamMember = (event, teamMemberIndex) => {
    event.preventDefault()

    setOpenTeamMember(teamMemberIndex)

    modalElement.current.showModal()
  }

  return (
    <>
      <section>
        <Edge
          location='top'
          direction='right'
          outerClassName='text-white'
          innerClassName='text-offwhite'
        />
        <div className='pt-16 pb-24 bg-offwhite'>
          <Container>
            <Heading
              className='h2 mb-20 text-center'
              {...props.data.primary.team_grid_title}
            />
            <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-5 gap-y-10'>
              {props.data.items.map((item, itemIndex) => {
                return (
                  <figure
                    key={itemIndex}
                  >
                    <div
                      className='mb-2 relative group cursor-pointer'
                      onClick={(event) => handleOpenTeamMember(event, itemIndex)}
                    >
                      <Image
                        fallbackAlt={item.team_grid_name.text}
                        {...item.team_grid_avatar}
                      />
                      <div className='hidden group-hover:block absolute z-10 inset-0 bg-midnight bg-opacity-50' />
                    </div>
                    <figcaption className='text-center'>
                      <p className='font-medium text-primary'>{item.team_grid_name.text}</p>
                      <p className='text-sm'>{item.team_grid_role.text}</p>
                    </figcaption>
                  </figure>
                )
              })}
            </div>
          </Container>
        </div>
      </section>
      <Modal ref={modalElement}>
        <div className='w-full max-w-md px-4'>
          <TeamGridModalContent
            key={openTeamMember}
            teamMembers={props.data.items}
            openTeamMember={openTeamMember}
          />
        </div>
      </Modal>
    </>
  )
}

TeamGrid.propTypes = {
  data: PropTypes.object.isRequired
}

export default TeamGrid

export const query = graphql`
  fragment TeamGrid on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyTeamGrid {
          id
          slice_type
          primary {
            team_grid_title {
              text
              raw
            }
          }
          items {
            team_grid_avatar {
              ...Image
            }
            team_grid_name {
              text
            }
            team_grid_role {
              text
            }
            team_grid_modal_header {
              text
            }
            team_grid_modal_body {
              html
            }
          }
        }
      }
    }
  }
`
