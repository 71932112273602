import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Container from '../../shared/Container/Container'
import Edge from '../../shared/Edge/Edge'
import CurriculumTabs from '../../shared/CurriculumTabs/CurriculumTabs'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'

const CourseCovered = (props) => {
  return (
    <section className='pb-8 md:pb-16 overflow-hidden'>
      <div
        className={classNames('relative', {
          [`bg-${props.data.primary.course_covered_background_color}`]: props.data.primary.course_covered_background_color
        })}
      >
        <Container width='thinner'>
          <div className='max-w-2xl'>
            <Heading
              className='h2 mb-10'
              {...props.data.primary.course_covered_title}
            />
            <CurriculumTabs
              tabs={props.data.items.map((tab) => {
                return {
                  value: tab.course_covered_tab_value.text,
                  header: tab.course_covered_tab_header.text,
                  body: tab.course_covered_tab_body.html,
                  footnote: tab.course_covered_tab_footnote.html
                }
              })}
            />
          </div>
        </Container>
        <div className='hidden lg:block absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 xl:translate-x-32 z-20 max-w-lg'>
          <Image
            fallbackAlt={props.data.primary.course_covered_title.text}
            {...props.data.primary.course_covered_illustration}
          />
        </div>
      </div>
      <Edge
        location='bottom'
        direction='right'
        outerClassName='text-white'
        innerClassName={`text-${props.data.primary.course_covered_background_color}`}
      />
    </section>
  )
}

CourseCovered.propTypes = {
  data: PropTypes.object.isRequired
}

export default CourseCovered

export const query = graphql`
  fragment CourseCovered on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyCourseCovered {
          id
          slice_type
          primary {
            display
            course_covered_title {
              text
              raw
            }
            course_covered_illustration {
              ...Image
            }
            course_covered_background_color
            has_bottom_edge
          }
          items {
            course_covered_tab_value {
              text
            }
            course_covered_tab_header {
              text
            }
            course_covered_tab_body {
              html
            }
            course_covered_tab_footnote {
              html
            }
          }
        }
      }
    }
  }
`
