import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Generic
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'

// Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade } from 'swiper'

// Swiper components
import SwiperNav from '../../shared/Swiper/SwiperNav'

SwiperCore.use([EffectFade])

const WhyCarousel = (props) => {
  const [swiper, setSwiper] = useState(null)

  return (
    <section className='mb-16 md:mb-32'>
      <Container width='thin' className='pb-16 sm:pb-32'>
        <Heading
          className='h2 mb-6 sm:mb-10'
          {...props.data.primary.why_carousel_title}
        />
        <SwiperNav
          swiper={swiper}
          wrapperClassName='sm:hidden mb-6'
          containerClassName='-ml-4'
          ctaClassName='bg-primary text-white hover:bg-opacity-25 hover:text-primary focus:bg-opacity-25 focus:text-primary'
        />
        <div className='shadow-lg pt-10 sm:pt-18 px-6 md:px-18 relative swiper-overflow-visible'>
          <Swiper
            onSwiper={setSwiper}
            spaceBetween={0}
            slidesPerView={1}
            effect='fade'
            loop
          >
            {props.data.items.map((item, itemIndex) => {
              return (
                <SwiperSlide key={itemIndex}>
                  <div className='sm:grid grid-cols-2 gap-x-6 pb-24 sm:pb-48 mb-4 sm:mb-0'>
                    <div className='space-y-6'>
                      <Heading
                        className='h3 text-primary font-bold'
                        {...item.why_carousel_header}
                      />
                      <HtmlContent
                        html={item.why_carousel_body_left.html}
                        className='text-lg c-prose'
                      />
                    </div>
                    <div className='hidden sm:block'>
                      <HtmlContent
                        html={item.why_carousel_body_right.html}
                        className='text-lg c-prose'
                      />
                    </div>
                  </div>
                  <div className='absolute right-0 top-1/1 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-xl -mt-24 sm:-mt-40 sm:transform sm:translate-x-4 xl:translate-x-24'>
                    <Image
                      fallbackAlt={item.why_carousel_header.text}
                      {...item.why_carousel_illustration}
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <SwiperNav
            swiper={swiper}
            wrapperClassName='hidden sm:block absolute bottom-0 left-0 z-10 transform -translate-y-20'
            containerClassName='px-18'
            ctaClassName='bg-primary text-white hover:bg-opacity-25 hover:text-primary focus:bg-opacity-25 focus:text-primary'
          />
        </div>
      </Container>
    </section>
  )
}

WhyCarousel.propTypes = {
  data: PropTypes.object.isRequired
}

export default WhyCarousel

export const query = graphql`
  fragment WhyCarousel on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyWhyCarousel {
          id
          slice_type
          primary {
            display
            why_carousel_title {
              raw
              text
            }
          }
          items {
            why_carousel_illustration {
              ...Image
            }
            why_carousel_header {
              text
              raw
            }
            why_carousel_body_right {
              html
            }
            why_carousel_body_left {
              html
            }
          }
        }
      }
    }
  }
`
