import React from 'react'

// Generic
import Pill from '../../shared/Pill/Pill'

const EventPlaceholder = () => {
  return (
    <div className='shadow-lg bg-gray-lighter'>
      <div className='w-full aspect-w-16 aspect-h-8 mb-4 flex-shrink-0'>
        <div className='bg-gray-light h-full' />
      </div>
      <div className='flex flex-col px-8 pb-8'>
        <div className='flex justify-center mb-4 h-26px'>
          <Pill>Coming soon</Pill>
        </div>
        <div className='h6 xl:h5 line-clamp-3 min-h-7'>
          <div className='bg-gray-light h-6 mb-3 mt-0.5' />
          <div className='bg-gray-light h-6 w-4/6' />
        </div>
        <div>
          <div className='bg-gray-light h-6 w-4/6' />
        </div>
      </div>
    </div>)
}

export default EventPlaceholder
