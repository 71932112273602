import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Generic
import Heading from '../../shared/Heading/Heading'
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'

const ContactLocations = (props) => {
  return (
    <section className='pb-16 md:pb-32'>
      <Container width='thin'>
        <Heading
          className='h2 mb-6'
          {...props.data.primary.contact_locations_title}
        />
        <div className='grid sm:grid-cols-2 gap-6'>
          {props.data.items.map((item, itemIndex) => {
            return (
              <div
                key={itemIndex}
              >
                <Heading
                  className='h5 mb-1'
                  {...item.contact_locations_header}
                />
                <HtmlContent
                  element='address'
                  html={item.contact_locations_address.html}
                  className='font-normal not-italic mb-8'
                />
                <HtmlContent
                  html={item.contact_locations_iframe}
                  className='c-responsive-iframe'
                  style={{ paddingTop: '100%' }}
                />
              </div>
            )
          })}
        </div>
      </Container>
    </section>
  )
}

ContactLocations.propTypes = {
  data: PropTypes.object.isRequired
}

export default ContactLocations

export const query = graphql`
  fragment ContactLocations on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyContactLocations {
          id
          slice_type
          primary {
            display
            contact_locations_title {
              text
              raw
            }
          }
          items {
            contact_locations_header {
              raw
              text
            }
            contact_locations_address {
              html
            }
            contact_locations_iframe
          }
        }
      }
    }
  }
`
