import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Generic
import Container from '../../shared/Container/Container'
import Embed from '../../shared/Embed/Embed'

const VideoEmbed = (props) => {
  return (
    <section className='mb-16 md:mb-24'>
      <Container width='thinnest'>
        <Embed
          embed={props.data.primary.embed}
          thumbnail={props.data.primary.custom_thumbnail.url ? props.data.primary.custom_thumbnail : null}
          title={props.data.primary.custom_title || props.data.primary.embed.title}
        />
      </Container>
    </section>
  )
}

VideoEmbed.propTypes = {
  data: PropTypes.object.isRequired
}

export default VideoEmbed

export const query = graphql`
  fragment VideoEmbed on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyVideoEmbed {
          id
          slice_type
          primary {
            display
            embed {
              ...Embed
            }
            custom_title
            custom_thumbnail {
              ...Image
            }
          }
        }
      }
    }
  }
`
