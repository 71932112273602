import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'

const BusinessThreeCol = (props) => {
  return (
    <section className='mb-16 md:mb-24'>
      <Container width='thin' className='mb-32 sm:mb-32 lg:mb-36'>
        <Heading
          className='h2 max-w-2xl'
          {...props.data.primary.business_three_col_title}
        />
      </Container>
      <Container>
        <div className='md:grid grid-cols-3 gap-x-6 space-y-32 md:space-y-0'>
          {props.data.items.map((item, itemIndex) => {
            const paddingTopValue = item.business_three_col_illustration.url !== null ? '24' : '16'
            return (
              <div
                key={itemIndex}
                className={`flex flex-col shadow-lg px-6 pt-${paddingTopValue} lg:px-10 pb-6 md:pb-16 relative max-w-sm md:max-w-none mx-auto md:mx-0`}
              >
                {item.business_three_col_illustration.url !== null ? (
                  <div className='px-6 lg:px-10 absolute w-full bottom-1/1 left-0 transform translate-y-1/2'>
                    <Image
                      fallbackAlt={item.business_three_col_header.text}
                      {...item.business_three_col_illustration}
                    />
                  </div>
                ) : item.business_three_col_icon.url !== null ? (
                  <div className='px-6 lg:px-10 absolute w-32 bottom-1/1 left-0 transform translate-y-1/2'>
                    <Image
                      fallbackAlt={item.business_three_col_header.text}
                      {...item.business_three_col_icon}
                    />
                  </div>
                ) : null}
                <Heading
                  className='h4 mb-2'
                  {...item.business_three_col_header}
                />
                <HtmlContent
                  html={item.business_three_col_body.html}
                  className='text-lg mb-6 c-prose'
                />
                <div className='mt-auto'>
                  <Button
                    color={item.cta_style}
                    icon
                    {...item.business_three_col_cta_link}
                  >
                    {item.business_three_col_cta_label.text}
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </section>
  )
}

BusinessThreeCol.propTypes = {
  data: PropTypes.object.isRequired
}

export default BusinessThreeCol

export const query = graphql`
  fragment BusinessThreeCol on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyBusinessThreeCol {
          id
          slice_type
          primary {
            display
            business_three_col_title {
              text
              raw
            }
          }
          items {
            business_three_col_header {
              text
              raw
            }
            business_three_col_body {
              html
            }
            business_three_col_cta_label {
              text
            }
            business_three_col_cta_link {
              ...Link
            }
            cta_style
            business_three_col_illustration {
              ...Image
            }
            business_three_col_icon {
              ...Image
            }
          }
        }
      }
    }
  }
`
