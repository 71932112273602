/**
 * Reformat the BlogArticle object for use on the ArticleGrid component
 * @param  {Array} blogArticlesNodes
 * @return {Array}
 */
const blogArticleDecorator = (blogArticlesNodes) => {
  return blogArticlesNodes.map((blogArticle) => {
    return {
      url: blogArticle.url,
      image: blogArticle.data.featured_image.thumbnails.grid.url ? blogArticle.data.featured_image.thumbnails.grid : null,
      category: blogArticle.data.categories[0].category.document?.data.title.text,
      title: blogArticle.data.title.text,
      body: blogArticle.data.intro.text,
      avatar: blogArticle.data.author.document?.data.avatar.url ? blogArticle.data.author.document?.data.avatar : null,
      name: blogArticle.data.author.document?.data.title.text,
      role: blogArticle.data.author.document?.data.role.text
    }
  })
}

export default blogArticleDecorator
