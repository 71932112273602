import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'

// Icons
import LogoSymbolRed from '../../../assets/img/logo-symbol-red.svg'

// Local components
import FiftyFiftyCollageTriangle from './FiftyFiftyCollageTriangle'

/**
 * FiftyFiftyCollage component
 * Boxes in this component are numbered 1 to 4 from largest to smallest.
 * 1 is the largest box and 4 is the smallest box.
 * @param  {Object} props
 */
const FiftyFiftyCollage = (props) => {
  // Sometimes, if the text is very long, we'll want to pull the text column up
  // a bit to reduce whitespace. If there are 500 characters or more, pull up.
  // 500 just happens to a be a nice mid-number for powering the logic here
  const isTallBody = (props.data.primary.fifty_fifty_collage_body.text.length > 500)

  return (
    <section className='relative overflow-hidden pb-12 sm:pb-0'>
      <Container className='relative z-10 sm:py-10 md:py-16 lg:py-24'>
        <div className='flex flex-col sm:flex-row sm:pt-32'>
          <div
            className={classNames('sm:w-7/12 relative', {
              'sm:order-1 sm:mr-6': props.data.primary.fifty_fifty_collage_text_order === 'Left',
              'sm:order-2 sm:ml-6': props.data.primary.fifty_fifty_collage_text_order === 'Right',
              'sm:-mt-32': isTallBody
            })}
          >
            <div className='hidden sm:block relative'>
              <div className='relative z-10'>
                <Image
                  fallbackAlt={props.data.primary.fifty_fifty_collage_title.text}
                  {...props.data.primary.fifty_fifty_collage_image_1}
                />
              </div>
              <FiftyFiftyCollageTriangle
                location={1}
                styleName={props.data.primary.fifty_fifty_collage_triangle_style}
              />
            </div>
            <div className='sm:max-w-lg sm:mx-auto sm:pt-18'>
              <Heading
                className='h2 mb-6'
                {...props.data.primary.fifty_fifty_collage_title}
              />
              {props.data.primary.fifty_fifty_collage_body.html && (
                <HtmlContent
                  html={props.data.primary.fifty_fifty_collage_body.html}
                  className='text-lg mb-10 c-prose'
                />
              )}
              {(props.data.primary.fifty_fifty_collage_cta_link.url && props.data.primary.fifty_fifty_collage_cta_label.text) && (
                <Button
                  color={props.data.primary.cta_style}
                  icon
                  {...props.data.primary.fifty_fifty_collage_cta_link}
                >
                  {props.data.primary.fifty_fifty_collage_cta_label.text}
                </Button>
              )}
            </div>
          </div>
          <div
            className={classNames('sm:w-5/12 sm:space-y-6 pt-10 sm:pt-0', {
              'sm:order-2': props.data.primary.fifty_fifty_collage_text_order === 'Left',
              'sm:order-1': props.data.primary.fifty_fifty_collage_text_order === 'Right',
              'sm:-mt-32': !isTallBody,
              'sm:pt-12': isTallBody
            })}
          >
            <div
              className={classNames('hidden sm:flex items-end space-x-6', {
                'space-x-reverse': props.data.primary.fifty_fifty_collage_text_order === 'Right'
              })}
            >
              <div
                className={classNames('w-3/5 relative', {
                  'order-2': props.data.primary.fifty_fifty_collage_text_order === 'Right'
                })}
              >
                <div className='relative z-10'>
                  <Image
                    fallbackAlt={props.data.primary.fifty_fifty_collage_title.text}
                    {...props.data.primary.fifty_fifty_collage_image_3}
                  />
                </div>
                <FiftyFiftyCollageTriangle
                  location={3}
                  styleName={props.data.primary.fifty_fifty_collage_triangle_style}
                />
              </div>
              <div
                className={classNames('w-2/5 relative', {
                  'order-1': props.data.primary.fifty_fifty_collage_text_order === 'Right'
                })}
              >
                <div className='relative z-10'>
                  <Image
                    fallbackAlt={props.data.primary.fifty_fifty_collage_title.text}
                    {...props.data.primary.fifty_fifty_collage_image_4}
                  />
                </div>
                <FiftyFiftyCollageTriangle
                  location={4}
                  styleName={props.data.primary.fifty_fifty_collage_triangle_style}
                />
              </div>
            </div>
            <div
              className={classNames('sm:w-10/12 relative', {
                'ml-auto': props.data.primary.fifty_fifty_collage_text_order === 'Right'
              })}
            >
              <div className='relative z-10'>
                <Image
                  fallbackAlt={props.data.primary.fifty_fifty_collage_title.text}
                  {...props.data.primary.fifty_fifty_collage_image_2}
                />
              </div>
              <FiftyFiftyCollageTriangle
                location={2}
                styleName={props.data.primary.fifty_fifty_collage_triangle_style}
              />
            </div>
          </div>
        </div>
      </Container>
      {props.data.primary.fifty_fifty_collage_show_logo && (
        <LogoSymbolRed className={classNames('hidden sm:block absolute z-0 top-0 w-2/5 md:w-64 lg:w-125 xl:w-150', {
          'left-0 -ml-32 md:-ml-24 lg:-ml-48 xl:-ml-64': props.data.primary.fifty_fifty_collage_show_logo === 'Left',
          'right-0 -mr-32 md:-mr-24 lg:-mr-48 xl:-mr-64': props.data.primary.fifty_fifty_collage_show_logo === 'Right'
        })}
        />
      )}
    </section>
  )
}

FiftyFiftyCollage.propTypes = {
  data: PropTypes.object.isRequired
}

export default FiftyFiftyCollage

export const query = graphql`
  fragment FiftyFiftyCollage on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyFiftyFiftyCollage {
          id
          slice_type
          primary {
            display
            fifty_fifty_collage_title {
              text
              raw
            }
            fifty_fifty_collage_text_order
            fifty_fifty_collage_body {
              html
              text
            }
            fifty_fifty_collage_cta_label {
              text
            }
            fifty_fifty_collage_cta_link {
              ...Link
            }
            cta_style
            fifty_fifty_collage_image_1 {
              ...Image
            }
            fifty_fifty_collage_image_2 {
              ...Image
            }
            fifty_fifty_collage_image_3 {
              ...Image
            }
            fifty_fifty_collage_image_4 {
              ...Image
            }
            fifty_fifty_collage_show_logo
            fifty_fifty_collage_triangle_style
          }
        }
      }
    }
  }
`
