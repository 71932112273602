import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import chunk from 'lodash.chunk'

// Local
import EventCard from './EventCard'
import EventPlaceholder from './EventPlaceholder'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import Edge from '../../shared/Edge/Edge'
import Heading from '../../shared/Heading/Heading'
import Image from '../../shared/Image/Image'

// Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Swiper components
import SwiperNav from '../../shared/Swiper/SwiperNav'

const EventbriteCarousel = (props) => {
  const [swiper, setSwiper] = useState(null)
  const isNarrowScreen = useMediaQuery('(max-width: 767px)')
  const isWideScreen = useMediaQuery('(min-width: 1024px)')

  const events = useStaticQuery(graphql`
    {
      data: allEventbriteEvent(
        filter: {
          listed: {
            eq: true
          }
        }
        sort: {
          fields: [start___utc]
          order: ASC
        }
        limit: 6
      ) {
        nodes {
          description {
            text
          }
          name {
            text
          }
          logo {
            url
            crop_mask {
              width
              height
            }
          }
          end {
            utc
          }
          start {
            utc
          }
          venue {
            name
          }
          online_event
          url
        }
      }
    }
  `)

  const eventGroupSize = isNarrowScreen ? 1 : isWideScreen ? 3 : 2
  const eventGroups = chunk(events.data.nodes, eventGroupSize)

  return (
    <section>
      <Container className='mb-16 md:mb-32'>
        <Heading className='h2 max-w-2xl mb-8' {...props.data.primary.eventbrite_title} />

        <div className='mb-10 relative swiper-overflow-visible'>
          <Swiper
            onSwiper={setSwiper}
            spaceBetween={0}
            slidesPerView={1}
            effect='fade'
            loop
          >
            {eventGroups.map((eventGroup, index) => (
              <SwiperSlide key={index}>
                <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-sm md:max-w-none mx-auto'>
                  {eventGroup.map((event, eventIndex) => (<EventCard {...event} key={eventIndex} />))}
                  {eventGroup.length < 1 && <EventPlaceholder />}
                  {!isNarrowScreen && eventGroup.length < 2 && <EventPlaceholder />}
                  {isWideScreen && eventGroup.length < 3 && <EventPlaceholder />}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className='flex gap-4'>
          {props.data.items.map((cta, index) => (
            <Button
              {...cta.eventbrite_cta_link}
              color={cta.eventbrite_cta_style}
              icon
              key={index}
            >
              {cta.eventbrite_cta_label.text}
            </Button>
          ))}

          {eventGroups.length > 1 && !isNarrowScreen && (
            <SwiperNav
              swiper={swiper}
              wrapperClassName='hidden md:block w-full z-10'
              containerClassName='justify-end transform -translate-y-8'
              ctaClassName='bg-primary text-white hover:bg-opacity-25 hover:text-primary focus:bg-opacity-25 focus:text-primary'
            />)}
        </div>
        {props.data?.primary?.eventbrite_illustration?.url && (
          <div className='hidden md:block'>
            <div className='absolute right-0 transform z-10 w-1/2 transform -translate-x-1/2 sm:translate-x-0 sm:ml-0 sm:w-full sm:max-w-md xl:max-w-lg'>
              <Image
                fallbackAlt={props.data.primary.eventbrite_title.text}
                {...props.data.primary.eventbrite_illustration}
              />
            </div>
          </div>
        )}
      </Container>

      {props.data.primary.eventbrite_include_edge && (
        <Edge
          location='bottom'
          direction='left'
          outerClassName='text-offwhite'
          innerClassName='text-white'
        />
      )}

    </section>
  )
}

export default EventbriteCarousel

EventbriteCarousel.propTypes = {
  data: PropTypes.object.isRequired
}

export const query = graphql`
  fragment EventbriteCarousel on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyEventbriteCarousel {
          id
          slice_type
          primary {
            eventbrite_title {
              html
              text
              raw
            }
            eventbrite_illustration {
              ...Image
            }
            eventbrite_include_edge
          }
          items {
            eventbrite_cta_label {
              text
            }
            eventbrite_cta_link {
              ...Link
            }
            eventbrite_cta_style
          }
        }
      }
    }
  }
`
