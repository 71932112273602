import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'

// Content Components
import HubspotForm from '../../elements/HubspotForm/HubspotForm'

// Generic
import Container from '../../shared/Container/Container'
import Button from '../../shared/Button/Button'
import Heading from '../../shared/Heading/Heading'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'

// Local (kind of)
import FooterLink from '../../layout/Footer/FooterLink'

const ContactTwoCol = (props) => {
  // Use the social links from the footer
  const footerLinks = useStaticQuery(graphql`
    {
      items: prismicFooterNavigation {
        data {
          body {
            ... on PrismicFooterNavigationBodyFooterSocial {
              slice_type
              items {
                subitem_platform
                subitem_link {
                  ...Link
                }
              }
            }
          }
        }
      }
    }
  `)
  const socialLinks = footerLinks.items.data.body.find(item => item.slice_type === 'footer_social')

  return (
    <section className='pb-16 md:pb-32 relative'>
      <Container width='thin'>
        <div className='sm:flex justify-between sm:space-x-6 md:space-x-10'>
          <div className='sm:w-1/2 pt-16 md:pt-32 mb-10 sm:mb-0 '>
            <Heading
              className='h2 mb-6'
              {...props.data.primary.contact_two_col_title}
            />
            <div className='space-y-6'>
              {props.data.items.map((row, rowIndex) => {
                if (row.contact_two_col_row_type === 'Social' && socialLinks) {
                  return (
                    <ul
                      key={rowIndex}
                      className='flex items-center space-x-4'
                    >
                      {socialLinks.items.map((link, linkIndex) => {
                        return (
                          <li key={linkIndex}>
                            <FooterLink
                              type={socialLinks.slice_type}
                              className='text-black'
                              {...link}
                            />
                          </li>
                        )
                      })}
                    </ul>
                  )
                }
                const styleLinks = row.contact_two_col_body_links_styled === 'No' || row.contact_two_col_body_links_styled === null ? 'c-prose--link-black' : ''
                return (
                  <Fragment key={rowIndex}>
                    <HtmlContent
                      key={rowIndex}
                      html={row.contact_two_col_body.html}
                      className={classNames(`c-prose ${styleLinks} c-prose--header-sibling-remove-mt`, {
                        'c-prose--header-primary': (row.contact_two_col_header_color === 'primary')
                      })}
                    />
                    {(row.contact_two_col_cta_label.text && row.contact_two_col_cta_link.url) && (
                      <Button
                        color={row.cta_style}
                        icon
                        {...row.contact_two_col_cta_link}
                      >
                        {row.contact_two_col_cta_label.text}
                      </Button>
                    )}
                  </Fragment>
                )
              })}
            </div>
          </div>
          <div className='sm:w-1/2 sm:max-w-lg border-t-8 border-primary shadow-lg p-10 sm:py-10 sm:px-6 md:px-10 lg:p-20 sm:-mt-20 bg-white' id={props.data.primary.contact_two_col_form_guid}>
            <HubspotForm formId={props.data.primary.contact_two_col_form_guid} />
          </div>
        </div>
      </Container>
    </section>
  )
}

ContactTwoCol.propTypes = {
  data: PropTypes.object.isRequired
}

export default ContactTwoCol

export const query = graphql`
  fragment ContactTwoCol on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyContactTwoCol {
          id
          slice_type
          primary {
            display
            contact_two_col_title {
              raw
              text
            }
            contact_two_col_form_guid
          }
          items {
            contact_two_col_row_type
            contact_two_col_body {
              html
            }
            contact_two_col_body_links_styled
            contact_two_col_header_color
            contact_two_col_cta_label {
              text
            }
            contact_two_col_cta_link {
              ...Link
            }
            cta_style
          }
        }
      }
    }
  }
`
