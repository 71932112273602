import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

const FacebookPixel = (props) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        window.fbq('track', `${props.data.primary.facebook_pixel_website_action}`)
      }
    }
  }, [])

  return null
}

export default FacebookPixel

FacebookPixel.propTypes = {
  data: PropTypes.object.isRequired
}

export const query = graphql`
fragment FacebookPixel on PrismicPage {
    data {
        body {
            ... on PrismicPageBodyFacebookPixel {
                id
                slice_type 
                primary {
                    facebook_pixel_website_action
                }
            }
        }
    }
}
`
