import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Pill from '../../shared/Pill/Pill'
import Heading from '../../shared/Heading/Heading'

const CourseHowItWorks = (props) => {
  return (
    <section
      className={classNames('py-16 md:py-24 -mt-16 md:-mt-32', {
        'bg-white': !props.data.primary.course_hiw_background_color,
        [`bg-${props.data.primary.course_hiw_background_color}`]: props.data.primary.course_hiw_background_color
      })}
    >
      <Container width='thinner'>
        <Heading
          className='h2 max-w-xl mb-10'
          {...props.data.primary.course_hiw_title}
        />
        <div className='space-y-4'>
          {props.data.items.map((item, itemIndex) => {
            return (
              <div
                key={itemIndex}
                className='sm:flex shadow-lg p-6 sm:p-10 space-y-8 sm:space-y-0 sm:space-x-8 md:space-x-16 bg-white'
              >
                <div className='space-y-1'>
                  <Pill>Duration</Pill>
                  <p className='h4 text-primary'>{item.course_hiw_duration_header.text}</p>
                  {item.course_hiw_duration_body.html && (
                    <HtmlContent
                      html={item.course_hiw_duration_body.html}
                      className='text-sm c-prose'
                    />
                  )}
                </div>
                <div className='space-y-1'>
                  <Pill>Location</Pill>
                  <p className='h4 text-primary'>{item.course_hiw_location_header.text}</p>
                  {item.course_hiw_location_body.html && (
                    <HtmlContent
                      html={item.course_hiw_location_body.html}
                      className='text-sm c-prose'
                    />
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </section>
  )
}

CourseHowItWorks.propTypes = {
  data: PropTypes.object.isRequired
}

export default CourseHowItWorks

export const query = graphql`
  fragment CourseHowItWorks on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyCourseHowItWorks {
          id
          slice_type
          primary {
            display
            course_hiw_title {
              text
              raw
            }
            course_hiw_background_color
          }
          items {
            course_hiw_duration_header {
              text
            }
            course_hiw_duration_body {
              html
            }
            course_hiw_location_header {
              text
            }
            course_hiw_location_body {
              html
            }
          }
        }
      }
    }
  }
`
