import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Edge from '../../shared/Edge/Edge'
import Heading from '../../shared/Heading/Heading'

const BusinessUpClose = (props) => {
  return (
    <section
      className={classNames('relative', {
        'mb-16 md:mb-24': !props.data.primary.business_up_close_illustration.url,
        'mb-32 md:mb-48': props.data.primary.business_up_close_illustration.url,
        'bg-white': !props.data.primary.business_up_close_background_color,
        [`bg-${props.data.primary.business_up_close_background_color}`]: props.data.primary.business_up_close_background_color
      })}
    >
      <Container className='pb-40 lg:pb-32'>
        <Heading
          className='h2 max-w-xl mb-10'
          {...props.data.primary.business_up_close_title}
        />
        <div className='sm:flex space-y-6 sm:space-y-0 sm:space-x-6'>
          {props.data.items.map((item, itemIndex) => {
            return (
              <div
                key={itemIndex}
                className='sm:w-1/2'
              >
                <div className='sm:max-w-md'>
                  <Heading
                    className='h5 mb-4 sm:max-w-xs'
                    {...item.business_up_close_header}
                  />
                  <HtmlContent
                    html={item.business_up_close_body.html}
                    className='text-lg c-prose'
                  />
                </div>
              </div>
            )
          })}
        </div>
      </Container>
      <Edge
        location='bottom'
        direction='right'
        outerClassName='text-white'
        innerClassName={`text-${props.data.primary.business_up_close_background_color || 'white'}`}
      />
      {props.data.primary.business_up_close_illustration.url && (
        <div className='absolute bottom-0 left-1/2 sm:left-0 transform translate-y-1/2 z-20 w-1/2 transform -translate-x-1/2 sm:translate-x-0 sm:ml-0 sm:w-full sm:max-w-md xl:max-w-lg'>
          <Image
            fallbackAlt={props.data.primary.business_up_close_title.text}
            {...props.data.primary.business_up_close_illustration}
          />
        </div>
      )}
    </section>
  )
}

BusinessUpClose.propTypes = {
  data: PropTypes.object.isRequired
}

export default BusinessUpClose

export const query = graphql`
  fragment BusinessUpClose on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyBusinessUpClose {
          id
          slice_type
          primary {
            display
            business_up_close_title {
              text
              raw
            }
            business_up_close_illustration {
              ...Image
            }
            business_up_close_background_color
          }
          items {
            business_up_close_header {
              text
              raw
            }
            business_up_close_body {
              html
            }
          }
        }
      }
    }
  }
`
