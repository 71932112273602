import React from 'react'
import PropTypes from 'prop-types'

const FiftyFiftyTriangle = (props) => {
  const { location } = props

  if (!location) {
    return null
  }

  const positions = {
    'Top Left': (
      <div className='text-secondary-light text-w-triangle-80 absolute top-0 left-0 -mt-4 -ml-4'>
        <span className='block triangle-left-up' />
      </div>
    ),
    'Top Middle': (
      <div className='text-secondary-light text-w-triangle-80 absolute top-0 right-0 z-20 -mt-4 mr-4 md:mr-20'>
        <span className='block triangle-right-up' />
      </div>
    ),
    'Top Right': (
      <div className='text-secondary-light text-w-triangle-80 absolute top-0 right-0 -mt-4 -mr-4'>
        <span className='block triangle-right-up' />
      </div>
    ),
    'Bottom Left': (
      <div className='text-secondary-light text-w-triangle-130 absolute bottom-0 left-0 z-20 -mb-6 -ml-6'>
        <span className='block triangle-left-down' />
      </div>
    ),
    'Bottom Right': (
      <div className='text-secondary-light text-w-triangle-130 absolute bottom-0 right-0 z-20 -mr-4 mb-6'>
        <span className='block triangle-right-down' />
      </div>
    )
  }

  if (positions[location]) {
    return positions[location]
  }

  console.log(`No style found for '${location}'`)
  return null
}

FiftyFiftyTriangle.propTypes = {
  location: PropTypes.string
}

export default FiftyFiftyTriangle
