import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Generic
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Button from '../../shared/Button/Button'
import Heading from '../../shared/Heading/Heading'

const CourseDates = (props) => {
  return (
    <section className='mb-16 md:mb-24'>
      <Container width='thinner'>
        <Heading
          className='h2 max-w-xl mb-10'
          {...props.data.primary.course_dates_title}
        />
        <div className='space-y-4'>
          {props.data.items.map((item, itemIndex) => {
            return (
              <div
                key={itemIndex}
                className='sm:flex justify-between items-center shadow-lg p-6 sm:p-10 space-y-6 sm:space-y-0 sm:space-x-8'
              >
                <div className='space-y-1'>
                  <p className='h4 text-primary'>{item.course_dates_date}</p>
                  {item.course_dates_body.html && (
                    <HtmlContent
                      html={item.course_dates_body.html}
                      className='text-sm c-prose'
                    />
                  )}
                </div>
                {item.course_dates_display_apply_now_button ? (
                  <Button
                    {...item.course_dates_cta_link}
                    color={item.cta_style}
                    icon
                  >
                    {item.course_dates_cta_label.text}
                  </Button>
                ) : null}
              </div>
            )
          })}
        </div>
      </Container>
    </section>
  )
}

CourseDates.propTypes = {
  data: PropTypes.object.isRequired
}

export default CourseDates

export const query = graphql`
  fragment CourseDates on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyCourseDates {
          id
          slice_type
          primary {
            display
            course_dates_title {
              text
              raw
            }
          }
          items {
            course_dates_date(formatString: "Do MMMM YYYY")
            course_dates_body {
              html
            }
            course_dates_cta_label {
              text
            }
            course_dates_cta_link {
              ...Link
            }
            cta_style
            course_dates_display_apply_now_button
          }
        }
      }
    }
  }
`
