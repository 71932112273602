import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import TwoUpItem from '../../shared/TwoUp/TwoUpItem'
import TwoUpWrapper from '../../shared/TwoUp/TwoUpWrapper'
import Heading from '../../shared/Heading/Heading'

// Icons
import LogoSymbolRed from '../../../assets/img/logo-symbol-red.svg'

const CoursesTwoUp = (props) => {
  return (
    <section className='relative mb-16 md:mb-32'>
      <Container className='relative z-10'>
        <Heading
          className='h2 mb-8 sm:mb-32'
          {...props.data.primary.courses_two_up_header}
        />
        <TwoUpWrapper>
          {props.data.items.map((item, itemIndex) => {
            return (
              <TwoUpItem
                key={itemIndex}
                pullUp={itemIndex === 1}
              >
                <div className='w-3/5 mb-6 md:-mt-24'>
                  <Image
                    fallbackAlt={item.courses_two_up_title.text}
                    {...item.courses_two_up_illustration}
                  />
                </div>
                <div className='space-y-4 mb-8'>
                  <Heading
                    className='h3'
                    {...item.courses_two_up_title}
                  />
                  <div className='flex items-center space-x-2'>
                    <p>{item.courses_two_up_location.text}</p>
                    <LogoSymbolRed className='w-4' />
                    <p>{item.courses_two_up_length.text}</p>
                  </div>
                  <HtmlContent
                    className='text-lg c-prose'
                    html={item.courses_two_up_body.html}
                  />
                </div>
                <Button
                  {...item.courses_two_up_cta_link}
                  color={item.cta_style}
                  icon
                >
                  {item.courses_two_up_cta_label.text}
                </Button>
              </TwoUpItem>
            )
          })}
        </TwoUpWrapper>
      </Container>
      <LogoSymbolRed className='hidden sm:block w-125 absolute left-0 top-1/2 transform -translate-y-1/2 -ml-32 mt-16' />
    </section>
  )
}

CoursesTwoUp.propTypes = {
  data: PropTypes.object.isRequired
}

export default CoursesTwoUp

export const query = graphql`
  fragment CoursesTwoUp on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyCoursesTwoUp {
          id
          slice_type
          primary {
            display
            courses_two_up_header {
              text
              raw
            }
          }
          items {
            courses_two_up_cta_link {
              ...Link
            }
            courses_two_up_cta_label {
              text
            }
            cta_style
            courses_two_up_illustration {
              ...Image
            }
            courses_two_up_length {
              text
            }
            courses_two_up_location {
              text
            }
            courses_two_up_title {
              text
              raw
            }
            courses_two_up_body {
              html
            }
          }
        }
      }
    }
  }
`
