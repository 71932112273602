import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Local components
import TestimonialCarousel from './TestimonialCarousel'

const PageTestimonialCarousel = (props) => {
  if (props.data.primary.testimonial_carousel.isBroken) {
    console.error('Linked Testimonial Carousel does not exist')
    return null
  }

  return (
    <TestimonialCarousel
      items={props.data.primary.testimonial_carousel.document.data.testimonial_carousel_items}
      hideBottomEdge={props.data.primary.hide_bottom_edge}
      sectionClassName={classNames('pt-16', {
        'mb-16 md:mb-32': !props.data.primary.hide_bottom_edge
      })}
    />
  )
}

PageTestimonialCarousel.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageTestimonialCarousel

export const query = graphql`
  fragment PageTestimonialCarousel on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyTestimonialCarousel {
          id
          slice_type
          primary {
            display
            testimonial_carousel {
              isBroken
              document {
                ...TestimonialCarousel
              }
            }
            hide_bottom_edge
          }
        }
      }
    }
  }
`
