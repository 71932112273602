import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { graphql } from 'gatsby'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'

// Local
import FiftyFiftyTriangle from './FiftyFiftyTriangle'

// Icons
import LogoSymbolRed from '../../../assets/img/logo-symbol-red.svg'
import Embed from '../../shared/Embed/Embed'

const FiftyFifty = (props) => {
  return (
    <section className='mb-16'>
      {props.data.primary.fifty_fifty_has_logo_and_edge && (
        <div>
          <div
            className={classNames(
              'relative bg-white h-9vw c-fifty-fifty-edge',
              {
                [`text-${props.data.primary.fifty_fifty_background_color}`]: props
                  .data.primary.fifty_fifty_background_color
              }
            )}
          >
            <span className='block w-full h-full absolute top-0 left-0 z-10 transform -skew-y-5 bg-current pointer-events-none origin-right' />
            <Container className='hidden lg:block relative'>
              <LogoSymbolRed className='absolute top-0 right-0 w-full max-w-lg transform -translate-y-1/2' />
            </Container>
          </div>
        </div>
      )}
      <div
        className={classNames(
          'relative z-20 pt-10 md:pt-24 pb-10 md:pb-32 overflow-hidden',
          {
            [`bg-${props.data.primary.fifty_fifty_background_color}`]: props
              .data.primary.fifty_fifty_background_color
          }
        )}
      >
        <Container
          width='thin'
          className={classNames({
            [`bg-${props.data.primary.fifty_fifty_background_color}`]: props
              .data.primary.fifty_fifty_background_color
          })}
        >
          <div
            className={classNames('md:flex justify-between md:space-x-8', {
              'md:space-x-reverse':
                props.data.primary.fifty_fifty_text_order === 'Right'
            })}
          >
            <div
              className={classNames('md:w-1/2 md:max-w-md mb-10 md:mb-0', {
                'order-1': props.data.primary.fifty_fifty_text_order === 'Left',
                'order-2':
                  props.data.primary.fifty_fifty_text_order === 'Right'
              })}
            >
              <Heading
                className='h2 mb-6'
                {...props.data.primary.fifty_fifty_title}
              />
              {props.data.primary.fifty_fifty_body.html && (
                <HtmlContent
                  html={props.data.primary.fifty_fifty_body.html}
                  className='text-lg mb-10 c-prose'
                />
              )}
              {props.data.primary.fifty_fifty_cta_label.text && props.data.primary.fifty_fifty_cta_link.url && (
                <Button color={props.data.primary.cta_style} {...props.data.primary.fifty_fifty_cta_link} icon>
                  {props.data.primary.fifty_fifty_cta_label.text}
                </Button>
              )}
              {props.data.primary.additionalBodyContent}
            </div>
            <div
              className={classNames('md:w-1/2 max-w-md', {
                'order-2 xl:-mr-24':
                  props.data.primary.fifty_fifty_text_order === 'Left',
                'order-1 xl:-ml-24':
                  props.data.primary.fifty_fifty_text_order === 'Right'
              })}
            >
              <div className='relative'>
                <div className='relative z-10'>
                  {props.data.primary.fifty_fifty_video?.title ? (
                    <Embed embed={props.data.primary.fifty_fifty_video} />
                  ) : (
                    <Image
                      fallbackAlt={props.data.primary.fifty_fifty_title.text}
                      {...props.data.primary.fifty_fifty_image}
                    />
                  )}
                </div>
                <FiftyFiftyTriangle
                  location={props.data.primary.fifty_fifty_triangle_style}
                />
                <div
                  className={classNames(
                    'hidden lg:block absolute bottom-0 z-20 w-full max-w-sm transform translate-y-1/2',
                    {
                      'left-0 -translate-x-1/4':
                        props.data.primary.fifty_fifty_triangle_style ===
                        'Bottom Right',
                      'right-0 translate-x-1/4':
                        props.data.primary.fifty_fifty_triangle_style !==
                        'Bottom Right'
                    }
                  )}
                >
                  <Image
                    fallbackAlt={props.data.primary.fifty_fifty_title.text}
                    {...props.data.primary.fifty_fifty_illustration}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  )
}

FiftyFifty.propTypes = {
  data: PropTypes.object.isRequired
}

export default FiftyFifty

export const query = graphql`
  fragment FiftyFifty on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyFiftyFifty {
          id
          slice_type
          primary {
            display
            fifty_fifty_title {
              text
              raw
            }
            fifty_fifty_body {
              html
            }
            fifty_fifty_cta_label {
              text
            }
            fifty_fifty_cta_link {
              ...Link
            }
            cta_style
            fifty_fifty_text_order
            fifty_fifty_image {
              ...Image
            }
            fifty_fifty_illustration {
              ...Image
            }
            fifty_fifty_video {
              ...Embed
            }
            fifty_fifty_triangle_style
            fifty_fifty_background_color
            fifty_fifty_has_logo_and_edge
          }
        }
      }
    }
  }
`
