import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Generic
import Container from '../../shared/Container/Container'
import Heading from '../../shared/Heading/Heading'
import Embed from '../../shared/Embed/Embed'
import Button from '../../shared/Button/Button'

const VideoSideBySide = (props) => {
  return (
    <div className='relative z-20 mb-16 md:mb-32 overflow-hidden'>
      <Container>
        <Heading className='h2 max-w-2xl mb-8' {...props.data.primary.video_section_title} />
        <div className='md:flex justify-between md:space-x-8'>
          <div className='md:w-1/2 mb-10 md:mb-0'>
            <div className='relative'>
              <div className='relative z-10'>
                <Embed
                  embed={props.data.primary.left_video_embed}
                  thumbnail={props.data.primary.left_video_custom_thumbnail.url ? props.data.primary.left_video_custom_thumbnail : null}
                  title={props.data.primary.left_video_custom_title || props.data.primary.left_video_embed.title}
                />
              </div>
            </div>
          </div>

          <div className='md:w-1/2 mb-10 md:mb-0'>
            <div className='relative'>
              <div className='relative z-10'>
                <Embed
                  embed={props.data.primary.right_video_embed}
                  thumbnail={props.data.primary.right_video_custom_thumbnail.url ? props.data.primary.right_video_custom_thumbnail : null}
                  title={props.data.primary.right_video_custom_title || props.data.primary.right_video_embed.title}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='mt-6 flex flex-col sm:flex-row items-start gap-4'>
          {props.data.items.map(cta => (
            <Button key={cta.cta_label} color={cta.cta_type} {...cta.cta_link} icon>{cta.cta_label}</Button>
          ))}
        </div>
      </Container>
    </div>
  )
}

VideoSideBySide.propTypes = {
  data: PropTypes.object.isRequired
}

export default VideoSideBySide

export const query = graphql`
  fragment VideoSideBySide on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyVideoSideBySide {
          id
          slice_type
          primary {
            video_section_title {
              text
              raw
            }
            left_video_embed {
              ...Embed
            }
            left_video_custom_title
            left_video_custom_thumbnail {
              ...Image
            }
            right_video_embed {
              ...Embed
            }
            right_video_custom_title
            right_video_custom_thumbnail {
              ...Image
            }
          }
            items {
              cta_label
              cta_type
              cta_link {
                ...Link
              }
            }
        }
      }
    }
  }
`
