import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Generic
import Heading from '../../shared/Heading/Heading'

// Local
import HubspotForm from './HubspotForm'

const PageHubspotForm = (props) => {
  return (
    <section className='mb-16'>
      <div className='max-w-2xl mx-auto px-4' id={props.data.primary.hubspot_form_guid}>
        <Heading
          className='h3 mb-10'
          {...props.data.primary.hubspot_form_title}
        />
        <HubspotForm formId={props.data.primary.hubspot_form_guid} />
      </div>
    </section>
  )
}

PageHubspotForm.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageHubspotForm

export const query = graphql`
  fragment PageHubspotForm on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyHubspotForm {
          id
          slice_type
          primary {
            display
            hubspot_form_title {
              text
              raw
            }
            hubspot_form_guid
          }
        }
      }
    }
  }
`
