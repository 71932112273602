import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import Heading from '../../shared/Heading/Heading'
import Pill from '../../shared/Pill/Pill'
import Embed from '../../shared/Embed/Embed'

// Icons
import LogoSymbolRed from '../../../assets/img/logo-symbol-red.svg'

const PodcastList = (props) => {
  return (
    <section className={classNames('relative py-32 mb-32', {
      'bg-white': !props.data.primary.background_color,
      [`bg-${props.data.primary.background_color}`]: props.data.primary.background_color
    })}
    >
      <Container className='relative z-10'>
        <Heading className='h2 max-w-2xl mb-8' {...props.data.primary.podcast_list_title} />
        {props.data.items.map((item, index) => {
          return (
            <div key={index} className='w-full mb-12'>
              <div className='flex flex-col sm:flex-row items-start'>
                <Heading className='h3 max-w-2xl mb-4 mr-4 md:mr-8' {...item.podcast_title} />
                <div className='flex flex-row sm:flex-col md:flex-row items-start md:items-center flex-shrink-0'>
                  <Pill className='mr-4 mb-4 flex-shrink-0'>{item.series_name}</Pill>
                  {item.coming_soon && <Pill className='mb-4 flex-shrink-0'>coming soon</Pill>}
                </div>
              </div>
              <p className='mb-4'>{item.speakers.text}</p>
              {item.podcast.embed_url && (
                <Embed embed={{ ...item.podcast }} />
              )}
            </div>
          )
        })}
        <div className='flex flex-col sm:flex-row items-start gap-4'>
          {props.data.primary.podcast_list_cta_1_label && (
            <Button color={props.data.primary.podcast_list_cta_1_style} {...props.data.primary.podcast_list_cta_1_link} icon>
              {props.data.primary.podcast_list_cta_1_label.text}
            </Button>
          )}
          {props.data.primary.podcast_list_cta_2_label && (
            <Button color={props.data.primary.podcast_list_cta_2_style} {...props.data.primary.podcast_list_cta_2_link} icon>
              {props.data.primary.podcast_list_cta_2_label.text}
            </Button>
          )}
        </div>
      </Container>
      <LogoSymbolRed className='hidden sm:block w-125 absolute right-0 top-1/2 transform -translate-y-1/2 -ml-32 mt-16' />
    </section>
  )
}

PodcastList.propTypes = {
  data: PropTypes.object.isRequired
}

export default PodcastList

export const query = graphql`
  fragment PodcastList on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyPodcastList {
          id
          slice_type
          primary {
            podcast_list_title {
              text
              raw
            }
            podcast_list_cta_1_label {
              text
            }
            podcast_list_cta_1_link {
              ...Link
            }
            podcast_list_cta_1_style
            podcast_list_cta_2_label {
              text
            }
            podcast_list_cta_2_link {
              ...Link
            }
            podcast_list_cta_2_style
            background_color
          }
          items {
            coming_soon
            podcast_title {
              text
              raw
            }
            speakers {
              text
            }
            series_name
            podcast {
              ...Embed
            }
          }
        }
      }
    }
  }
`
