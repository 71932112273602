import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Heading from '../../shared/Heading/Heading'
import Image from '../../shared/Image/Image'
import Container from '../../shared/Container/Container'

// Local
import HeroMinimal from './HeroMinimal'

const PageHeroMinimal = (props) => {
  const isPrimary = props.data.primary.background_colour === 'primary'
  return (
    <HeroMinimal backgroundColour={isPrimary ? 'primary' : 'offwhite'}>
      <Heading
        className={classNames('h1', { 'text-white': isPrimary })}
        {...props.data.primary.hero_minimal_title}
      />
      {props.data.primary.hero_minimal_illustration.url && (
        <div className='absolute bottom-0 left-0 w-full'>
          <Container>
            <div className='max-w-lg w-1/2 sm:w-1/3'>
              <Image
                fallbackAlt={props.data.primary.hero_minimal_title.text}
                {...props.data.primary.hero_minimal_illustration}
              />
            </div>
          </Container>
        </div>
      )}
    </HeroMinimal>
  )
}

PageHeroMinimal.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageHeroMinimal

export const query = graphql`
  fragment HeroMinimal on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyHeroMinimal {
          id
          slice_type
          primary {
            background_colour
            hero_minimal_title {
              raw
              text
            }
            hero_minimal_illustration {
              ...Image
            }
          }
        }
      }
    }
  }
`
