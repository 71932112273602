import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import TwoUpItem from '../../shared/TwoUp/TwoUpItem'
import TwoUpWrapper from '../../shared/TwoUp/TwoUpWrapper'
import Heading from '../../shared/Heading/Heading'

// Icons
import LogoSymbolRed from '../../../assets/img/logo-symbol-red.svg'

const HeroBlocks = (props) => {
  if (props.data.items.length) {
    return (
      <Container
        element='section'
        className='mb-16 sm:mb-0'
      >
        <TwoUpWrapper className='relative z-40 -mt-20 sm:-mt-64 md:-mt-80'>
          {props.data.items.map((block, blockIndex) => {
            return (
              <TwoUpItem
                key={blockIndex}
                pullUp={blockIndex === 1}
              >
                <p className='uppercase italic font-medium text-sm tracking-wide mb-4'>{block.hero_block_header.text}</p>
                <LogoSymbolRed className='w-4 mb-8' />
                <Heading
                  className='h3 mb-4'
                  {...block.hero_block_title}
                />
                <div className='mb-8'>
                  <HtmlContent
                    html={block.hero_block_body.html}
                    className='c-prose'
                  />
                </div>
                <Button
                  {...block.hero_block_cta_link}
                  color={block.cta_style}
                  size='small'
                  icon
                >
                  {block.hero_block_cta_label.text}
                </Button>
              </TwoUpItem>
            )
          })}
        </TwoUpWrapper>
      </Container>
    )
  }

  return null
}

HeroBlocks.propTypes = {
  blocks: PropTypes.array
}

export default HeroBlocks

export const query = graphql`
  fragment HeroBlocks on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyHeroBlocks {
          id
          slice_type
          items {
            hero_block_title {
              text
              raw
            }
            hero_block_header {
              text
            }
            hero_block_cta_label {
              text
            }
            hero_block_cta_link {
              ...Link
            }
            cta_style
            hero_block_body {
              html
            }
          }
        }
      }
    }
  }
`
