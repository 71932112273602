import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import SwiperNav from '../../shared/Swiper/SwiperNav'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'
import LogoGroup from '../../shared/LogoGroup/LogoGroup'

// Utils
import getBreakpointWithoutUnit from '../../../utils/getBreakpointWithoutUnit'

SwiperCore.use([Pagination])

const Clients = (props) => {
  const [swiper, setSwiper] = useState(null)

  return (
    <section className='mb-20 pt-8'>
      <Container>
        <div className='shadow-lg pt-18 pb-12 px-4 md:px-20'>
          <Heading
            className='h5 text-center mb-4 md:mb-12'
            {...props.data.primary.client_title}
          />
          <SwiperNav
            swiper={swiper}
            wrapperClassName='md:hidden mb-10 md:mb-0'
            containerClassName='justify-center'
            ctaClassName='bg-primary text-white hover:bg-opacity-25 hover:text-primary focus:bg-opacity-25 focus:text-primary'
          />
          <Swiper
            onSwiper={setSwiper}
            spaceBetween={50}
            slidesPerView={3}
            slidesPerGroup={3}
            pagination={{ clickable: true }}
            loop
            className='pb-20 swiper-items-center'
            breakpoints={{
              [getBreakpointWithoutUnit('sm')]: {
                slidesPerGroup: 4,
                slidesPerView: 4
              },
              [getBreakpointWithoutUnit('md')]: {
                slidesPerGroup: 5,
                slidesPerView: 5
              }
            }}
            preloadImages={false}
            lazy
          >
            {props.data.primary.client_set.document.data.client_clients.map((client, clientIndex) => {
              return (
                <SwiperSlide
                  key={clientIndex}
                >
                  <div
                    className='relative'
                    style={{ paddingTop: '70%' }}
                  >
                    <Image
                      fallbackAlt={`Client ${clientIndex + 1}`}
                      className='absolute inset-0 w-full h-full'
                      lazyImageClassName='object-contain w-full h-full'
                      {...client.client_logo}
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        {(props.data.primary.client_cta_label.text && props.data.primary.client_cta_link.url) && (
          <div className='text-center pt-12'>
            <Button
              color={props.data.primary.cta_style}
              icon
              {...props.data.primary.client_cta_link}
            >
              {props.data.primary.client_cta_label.text}
            </Button>
          </div>
        )}
        <LogoGroup
          className='justify-center pt-16'
          logosDocument={props.data.primary.logo_group.document}
          fallbackAlt={props.data.primary.client_title.text}
        />
      </Container>
    </section>
  )
}

export default Clients

export const query = graphql`
  fragment Clients on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyClients {
          id
          slice_type
          primary {
            display
            client_title {
              text
              raw
            }
            client_set {
              document {
                ... on PrismicClient {
                  data {
                    client_clients {
                      client_logo {
                        ...Image
                      }
                    }
                  }
                }
              }
            }
            client_cta_label {
              text
            }
            client_cta_link {
              ...Link
            }
            cta_style
            logo_group {
              ...LogoGroup
            }
          }
        }
      }
    }
  }
`
