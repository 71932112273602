import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

// Generic
import ArticleGridWithTitle from '../../shared/ArticleGrid/ArticleGridWithTitle'

// Utils
import blogArticleDecorator from '../../../utils/blogArticleDecorator'

const LatestBlogPosts = (props) => {
  const latestBlogArticles = useStaticQuery(graphql`
    {
      blogArticles: allPrismicBlogArticle(
        limit: 3,
        sort: {
          fields: data___date,
          order: DESC
        }
      ) {
        nodes {
          ...BlogArticle
        }
      }
    }
  `)

  return (
    <ArticleGridWithTitle
      className='mb-16'
      title={props.data.primary.latest_blog_posts_title}
      articles={blogArticleDecorator(latestBlogArticles.blogArticles.nodes)}
      singleRow
      hasBackgroundAndTopEdge={props.data.primary.has_background_and_top_edge}
    />
  )
}

LatestBlogPosts.propTypes = {
  data: PropTypes.object.isRequired
}

export default LatestBlogPosts

export const query = graphql`
  fragment LatestBlogPosts on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyLatestBlogPosts {
          id
          slice_type
          primary {
            display
            latest_blog_posts_title {
              text
              raw
            }
            has_background_and_top_edge
          }
        }
      }
    }
  }
`
