import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const FiftyFiftyCollageTriangle = (props) => {
  /**
   * Predefined triangle sets
   * Add more here and in the CMS if they need extending
   * `location` refers to which box it sits in. Box `1` is the largest box
   * whereas box `4` is the smallest box
   * `markup` format is:
   * - Outer element which controls positioning, colour and size
   * - Inner element which controls the direction
   * @type {Object}
   */
  const triangleSets = {
    'Style 1': [{
      location: 1,
      markup: (
        <div className='text-secondary-light text-w-triangle-90 absolute top-0 right-0 z-20 -mt-6 mr-16'>
          <span className='block triangle-right-up' />
        </div>
      )
    }, {
      location: 1,
      markup: (
        <div className='text-primary text-w-triangle-260 absolute bottom-0 left-0 z-0 mb-16 -ml-20'>
          <span className='block triangle-left-down' />
        </div>
      )
    }, {
      location: 2,
      markup: (
        <div className='text-secondary-light text-w-triangle-90 absolute bottom-0 right-0 z-0 -mr-12 -mb-4'>
          <span className='block triangle-right-down' />
        </div>
      )
    }],

    'Style 2': [{
      location: 1,
      markup: (
        <div className='text-secondary-light text-w-triangle-90 absolute bottom-0 right-0 z-20 -mb-16 -mr-6'>
          <span className='block triangle-right-up' />
        </div>
      )
    }, {
      location: 2,
      markup: (
        <div className='text-secondary-light text-w-triangle-130 absolute bottom-0 left-0 z-20 -ml-4 -mb-4'>
          <span className='block triangle-left-down' />
        </div>
      )
    }],

    'Style 3': [{
      location: 1,
      markup: (
        <div className='text-secondary-light text-w-triangle-80 absolute top-0 left-0 z-20 -mt-4 -ml-4'>
          <span className='block triangle-left-up' />
        </div>
      )
    }, {
      location: 2,
      markup: (
        <div className='text-primary text-w-triangle-90 absolute bottom-0 right-0 z-20 -mr-5 -mb-12 sm:-mb-16'>
          <span className='block triangle-right-up' />
        </div>
      )
    }, {
      location: 4,
      markup: (
        <div className='text-secondary-light text-w-triangle-70 absolute top-0 right-0 z-20 -mr-8 mt-10'>
          <span className='block triangle-right-up' />
        </div>
      )
    }],

    'Style 4': [{
      location: 1,
      markup: (
        <div className='text-secondary-light text-w-triangle-130 absolute bottom-0 right-0 z-20 -mb-24'>
          <span className='block triangle-right-up' />
        </div>
      )
    }, {
      location: 2,
      markup: (
        <div className='text-secondary-light text-w-triangle-70 absolute bottom-0 left-0 z-20 -ml-4 -mb-3'>
          <span className='block triangle-left-down' />
        </div>
      )
    }]
  }

  if (triangleSets[props.styleName]) {
    return triangleSets[props.styleName].map((triangle, triangleIndex) => {
      if (triangle.location === props.location) {
        return (
          <Fragment key={triangleIndex}>
            {triangle.markup}
          </Fragment>
        )
      }

      return null
    })
  }

  return null
}

FiftyFiftyCollageTriangle.propTypes = {
  location: PropTypes.number.isRequired,
  styleName: PropTypes.string
}

export default FiftyFiftyCollageTriangle
