import React from 'react'

const HeroMinimal = ({ children, backgroundColour = 'offwhite' }) => {
  return (
    <section className={`py-32 lg:py-56 bg-${backgroundColour} relative`}>
      <div className='max-w-2xl mx-auto px-4 text-center'>
        {children}
      </div>
    </section>
  )
}

export default HeroMinimal
