import React from 'react'
import PropTypes from 'prop-types'

// Generic
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'

const TeamGridModalContent = (props) => {
  const { teamMembers, openTeamMember } = props

  if (openTeamMember === null) {
    return null
  }

  return (
    <>
      <figure className='flex items-center space-x-4 pb-6 border-b border-gray-lighter'>
        <div className='w-12'>
          <Image
            fallbackAlt={teamMembers[openTeamMember].team_grid_name.text}
            {...teamMembers[openTeamMember].team_grid_avatar}
          />
        </div>
        <figcaption className='h3'>
          {teamMembers[openTeamMember].team_grid_name.text}
        </figcaption>
      </figure>
      {teamMembers[openTeamMember].team_grid_modal_header.text && (
        <p className='h4 py-6 border-b border-gray-lighter'>{teamMembers[openTeamMember].team_grid_modal_header.text}</p>
      )}
      {teamMembers[openTeamMember].team_grid_modal_body.html && (
        <HtmlContent
          html={teamMembers[openTeamMember].team_grid_modal_body.html}
          className='py-6 border-b border-gray-lighter c-prose c-prose--header-primary'
        />
      )}
    </>
  )
}

TeamGridModalContent.propTypes = {
  teamMembers: PropTypes.array.isRequired,
  openTeamMember: PropTypes.number
}

export default TeamGridModalContent
