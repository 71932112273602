import React from 'react'
import PropTypes from 'prop-types'

// Hooks
import useSiteMetadata from '../../../hooks/useSiteMetadataQuery'

// Icons
import IconFacebook from '../../../assets/img/share/facebook.svg'
import IconTwitter from '../../../assets/img/share/twitter.svg'
import IconLinkedIn from '../../../assets/img/share/linkedin.svg'
import IconLink from '../../../assets/img/share/link.svg'
import ShareLinks from '../ShareLinks/ShareLinks'

const FollowLinks = (props) => {
  const { className, title, url } = props

  const { site: { siteMetadata } } = useSiteMetadata()

  // Generate the absolute URL based on the site metadata
  const absoluteUrl = `${siteMetadata.siteUrl}${url}`

  const platforms = [{
    name: 'Facebook',
    url: 'https://facebook.com/northcoders',
    icon: <IconFacebook className='w-6' />,
    type: 'new_tab'
  }, {
    name: 'Twitter',
    url: 'https://twitter.com/intent/user?screen_name=northcoders',
    icon: <IconTwitter className='w-6' />,
    type: 'new_tab'
  }, {
    name: 'LinkedIn',
    url: 'https://uk.linkedin.com/company/northcoders',
    icon: <IconLinkedIn className='w-6' />,
    type: 'new_tab'
  }, {
    name: 'Link',
    url: absoluteUrl,
    icon: <IconLink className='w-6' />,
    type: 'copy'
  }]

  return <ShareLinks className={className} title={title} url={url} platforms={platforms} />
}

FollowLinks.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  platforms: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.element,
    type: PropTypes.string.isRequired
  }))
}

export default FollowLinks
