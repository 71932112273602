import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const TwoUpWrapper = (props) => {
  const { children, className } = props

  return (
    <div className={classNames('md:flex justify-center space-y-4 md:space-y-0 md:space-x-6', className)}>
      {children}
    </div>
  )
}

TwoUpWrapper.propTypes = {
  classname: PropTypes.string
}

export default TwoUpWrapper
