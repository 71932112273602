import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'

// Generic
import Pill from '../../shared/Pill/Pill'
import Image from '../../shared/Image/Image'

const EventCard = ({ name, logo, end, start, venue, online_event: onlineEvent, url }) => {
  return (
    <div className='shadow-lg bg-white'>
      <a
        href={url}
        className='flex flex-col'
      >
        {logo?.url ? (
          <div className='w-full mb-4 flex-shrink-0'>
            <Image
              fallbackAlt={name.text}
              {...logo}
              dimensions={logo.crop_mask}
            />
          </div>
        ) : (
          <div className='w-full aspect-w-16 aspect-h-8 mb-4 flex-shrink-0'>
            <div className='bg-gray-light h-full' />
          </div>
        )}
        <div className='flex flex-col h-full px-8 pb-8'>

          <div className='flex justify-center mb-4 h-26px'>
            {venue.name && <Pill className='mr-4'>{venue.name}</Pill>}
            {onlineEvent && <Pill>Online</Pill>}
          </div>

          <p className='h6 xl:h5 line-clamp-3 min-h-7'>{name.text}</p>

          <p>
            <span className='font-bold'>{format(new Date(start.utc), 'd MMMM y')}</span>
            {` (${format(new Date(start.utc), 'HH:mm')} - ${format(new Date(end.utc), 'HH:mm')})`}
          </p>

        </div>
      </a>
    </div>
  )
}

EventCard.propTypes = {
  name: PropTypes.shape({
    text: PropTypes.string
  }),
  logo: PropTypes.shape({
    url: PropTypes.string,
    crop_mask: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number
    })
  }),
  end: PropTypes.shape({
    utc: PropTypes.string
  }),
  start: PropTypes.shape({
    utc: PropTypes.string
  }),
  venue: PropTypes.shape({
    name: PropTypes.string
  }),
  online_event: PropTypes.bool,
  url: PropTypes.string
}

export default EventCard
