import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Generic
import Container from '../../shared/Container/Container'
import Button from '../../shared/Button/Button'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'

const FinancePlans = (props) => {
  return (
    <section className='mb-16 md:mb-32'>
      <Container width='thinner'>
        <div className='max-w-2xl space-y-5 mb-4'>
          <Heading
            className='h2'
            {...props.data.primary.finance_plans_title}
          />
          <HtmlContent
            html={props.data.primary.finance_plans_body.html}
            className='text-lg c-prose'
          />
        </div>
        {props.data.items.map((item, itemIndex) => {
          if (item.finance_plans_element_type === 'Header') {
            return (
              <Heading
                key={itemIndex}
                className='h3 pt-16'
                {...item.finance_plans_header}
              />
            )
          }
          return (
            <div
              key={itemIndex}
              className='pt-10 border-b border-gray-lighter'
            >
              <div className='w-48 mb-6'>
                <Image
                  fallbackAlt={item.finance_plans_header.text}
                  {...item.finance_plans_image}
                />
              </div>
              <HtmlContent
                html={item.finance_plans_body.html}
                className='mb-8 c-prose'
              />
              <div>
                {[...Array(2)].map((_, index) => index + 1).map((i) => {
                  if (item[`finance_plans_cta_${i}_link`].url && item[`finance_plans_cta_${i}_label`]) {
                    return (
                      <Button
                        key={i}
                        color='inline'
                        icon
                        className={classNames('mb-10', { 'mr-10': i === 1 })}
                        {...item[`finance_plans_cta_${i}_link`]}
                      >
                        {item[`finance_plans_cta_${i}_label`]}
                      </Button>
                    )
                  }

                  return null
                })}
              </div>
            </div>
          )
        })}
      </Container>
    </section>
  )
}

FinancePlans.propTypes = {
  data: PropTypes.object.isRequired
}

export default FinancePlans

export const query = graphql`
  fragment FinancePlans on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyFinancePlans {
          id
          slice_type
          primary {
            display
            finance_plans_title {
              text
              raw
            }
            finance_plans_body {
              html
            }
          }
          items {
            finance_plans_element_type
            finance_plans_header {
              text
              raw
            }
            finance_plans_body {
              html
            }
            finance_plans_image {
              ...Image
            }
            finance_plans_cta_1_label
            finance_plans_cta_1_link {
              ...Link
            }
            finance_plans_cta_2_label
            finance_plans_cta_2_link {
              ...Link
            }
          }
        }
      }
    }
  }
`
