import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'

// Content Components
import HubspotForm from '../../elements/HubspotForm/HubspotForm'

// Generic
import Container from '../../shared/Container/Container'
import Edge from '../../shared/Edge/Edge'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import TwoColGrid from '../../shared/TwoColGrid/TwoColGrid'
import TwoColGridItem from '../../shared/TwoColGrid/TwoColGridItem'
import Embed from '../../shared/Embed/Embed'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'

const InfoGrid = (props) => {
  return (
    <section
      className={classNames({
        'mb-16 md:mb-32': (!props.data.primary.info_grid_video_embed.html && !props.data.primary.info_grid_image.url && props.data.primary.info_grid_remove_bottom_margin === 'No')
      })}
    >
      <div
        className={classNames('bg-primary', {
          'pb-40 sm:pb-48': props.data.primary.info_grid_hubspot_form_guid
        })}
      >
        {props.data.primary.info_grid_has_edge === 'Top' && (
          <Edge
            location='top'
            direction='right'
            outerClassName='text-white'
            innerClassName='text-primary'
          />
        )}
        <Container width='thinner' className='py-10 sm:py-20 text-white'>
          <div className='space-y-4 max-w-2xl mb-14'>
            <Heading
              className='h2'
              {...props.data.primary.info_grid_title}
            />
            {props.data.primary.info_grid_intro.html && (
              <HtmlContent
                html={props.data.primary.info_grid_intro.html}
                className='text-xl c-prose'
              />
            )}
          </div>
          <TwoColGrid>
            {props.data.items.map((item, itemIndex) => {
              return (
                <TwoColGridItem
                  key={itemIndex}
                  icon={item.info_grid_icon}
                  header={item.info_grid_header}
                  body={item.info_grid_body.html}
                />
              )
            })}
          </TwoColGrid>
        </Container>
        {(props.data.primary.info_grid_video_embed.html || props.data.primary.info_grid_image.url) && (
          <div className='relative pb-16 md:pb-32'>
            <Container width='thinner' className='relative z-10'>
              {props.data.primary.info_grid_video_embed.html ? (
                <Embed
                  embed={props.data.primary.info_grid_video_embed}
                  title={props.data.primary.info_grid_video_embed.title}
                />
              ) : (
                <Image
                  fallbackAlt={props.data.primary.info_grid_title.text}
                  {...props.data.primary.info_grid_image}
                />
              )}
            </Container>
            <div
              className={classNames('absolute bottom-0 left-0 w-full', {
                'h-1/2': !props.data.primary.info_grid_media_overlap_height,
                [`h-${props.data.primary.info_grid_media_overlap_height}`]: props.data.primary.info_grid_media_overlap_height,
                'bg-white': !props.data.primary.info_grid_media_overlap_background,
                [`bg-${props.data.primary.info_grid_media_overlap_background}`]: props.data.primary.info_grid_media_overlap_background
              })}
            />
          </div>
        )}
      </div>
      {props.data.primary.info_grid_has_edge === 'Bottom' && (
        <Edge
          location='bottom'
          direction='left'
          outerClassName='text-white'
          innerClassName='text-primary'
        />
      )}
      {props.data.primary.info_grid_hubspot_form_guid && (
        <div className='relative pb-16 md:pb-32 -mt-40 sm:-mt-64'>
          <Container width='thinner'>
            <div className='bg-white shadow-lg py-10 px-6 sm:px-10 sm:py-20 md:px-20'>
              <div className='c-hubspot-form'>
                <HubspotForm formId={props.data.primary.info_grid_hubspot_form_guid} />
              </div>
            </div>
          </Container>
        </div>
      )}
    </section>
  )
}

InfoGrid.propTypes = {
  data: PropTypes.object.isRequired
}

export default InfoGrid

export const query = graphql`
  fragment InfoGrid on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyInfoGrid {
          id
          slice_type
          primary {
            display
            info_grid_image {
              ...Image
            }
            info_grid_intro {
              html
            }
            info_grid_title {
              text
              raw
            }
            info_grid_video_embed {
              ...Embed
            }
            info_grid_media_overlap_background
            info_grid_media_overlap_height
            info_grid_hubspot_form_guid
            info_grid_has_edge
            info_grid_remove_bottom_margin
          }
          items {
            info_grid_icon {
              ...Image
            }
            info_grid_header {
              text
              raw
            }
            info_grid_body {
              html
            }
          }
        }
      }
    }
  }
`
